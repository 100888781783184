import React from "react"
import { Link, graphql } from "gatsby"
import Styled from "styled-components"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBlock from "../components/contentBlock"
import Title from "../components/title"
import TagBar from "../components/tagBar"
import BreadcrumbsBar from "../components/breadcrumbsBar"
import Breadcrumbs from "../components/breadcrumbs"

import { rhythm } from "../utils/typography"
import { colors } from "../utils/colors"

const Article = Styled.article`
  max-width: 100%;
  margin: auto;
  // padding-left: ${rhythm(0.5)};
  // padding-right: ${rhythm(0.5)};

  ul {
    padding-left: ${rhythm(1)};
  }
`
const Info = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-evenly;
  margin: ${rhythm(1 / 4)};
  span {
  }
`
const Links = Styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${rhythm(0.5)} 0;
`
const PrevLink = Styled(Link)`
  padding: ${rhythm(0.05)} ${rhythm(0.3)};
  margin: ${rhythm(0.2)} ${rhythm(0.5)};
  box-shadow: none;
  border-radius: ${rhythm(0.2)};
  border: 1px solid ${colors.gray};
  color: ${colors.darkGray};
  transition: all 0.2s ease-in;
  &:hover {
    color: black;
    border: 1px solid black;
  }
`
const NextLink = Styled(Link)`
  padding: ${rhythm(0.05)} ${rhythm(0.3)};
  margin: ${rhythm(0.2)} ${rhythm(0.5)};
  box-shadow: none;
  border-radius: ${rhythm(0.2)};
  border: 1px solid ${colors.gray};
  color: ${colors.darkGray};
  transition: all 0.2s ease-in;
  &:hover {
    color: black;
    border: 1px solid black;
  }
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <BreadcrumbsBar>
           <Breadcrumbs name="FeedYourWeb" page="/" />
           <Breadcrumbs name="Blog" page="/blog" />
           <Breadcrumbs name={post.frontmatter.title} />
        </BreadcrumbsBar>

        <ContentBlock>
          <Article>
            <header>
              <Title className="darg-gray">{post.frontmatter.title}</Title>
              <Info>
                <Bio small />
                <span>, {post.frontmatter.date}</span>
              </Info>
            </header>

            <TagBar tags={post.frontmatter.tags} />

            <section dangerouslySetInnerHTML={{ __html: post.html }} />
          </Article>
        </ContentBlock>

        <ContentBlock>
          <TagBar tags={post.frontmatter.tags} />
        </ContentBlock>

        <ContentBlock theme="white">
          <footer>
            <Bio />
          </footer>
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />

          <Links>
            {previous && (<PrevLink to={previous.fields.slug} rel="prev">← {previous.frontmatter.title}</PrevLink>)}
            {next && (<NextLink to={next.fields.slug} rel="next">{next.frontmatter.title} →</NextLink>)}
          </Links>
        </ContentBlock>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      rawMarkdownBody
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`

export default BlogPostTemplate;
